import { DateTime } from 'luxon';
import { Path } from './Path.js';

export async function exportJobMilestones(jobIds, attachers, { jobPermissions = {} } = {}) {
  const columns = [
    { name: 'Bucket' },
    { name: 'FCC Timeline' },
    { name: 'App#' },
    { name: 'Current Status' },
    { name: 'Pole Count' },
    {
      name: 'Engineering Required',
      types: {
        '2.0': { value: true },
        '3.0': { value: true },
        regular: { metadata: 'engineering_required' },
        'service-drop': { metadata: 'engineering_required' },
        'non-fcc': { metadata: 'engineering_required' }
      }
    },
    {
      name: 'Construction Required',
      types: {
        '2.0': { fallback: checkMakeReadyCategory },
        '3.0': { fallback: checkMakeReadyCategory },
        regular: { fallback: checkMakeReadyCategory },
        'service-drop': { fallback: checkMakeReadyCategory },
        'non-fcc': { fallback: checkMakeReadyCategory }
      }
    },
    {
      name: 'Date OTMR Initial Request',
      types: {
        '2.0': { app_status: 'Reviewing for Completeness (One Touch)' },
        '3.0': {
          survey_metadata: 'date_of_otmr_request',
          fallback: async (jobId) =>
            await FirebaseWorker.ref(`photoheight/jobs/${jobId}/date_created`)
              .once('value')
              .then((s) => s.val())
        }
      }
    },
    {
      name: 'Date of OTMR start',
      types: {
        '2.0': {
          metadata: 'date_otmr_submitted',
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const dateSubmittedRecords = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'date_submitted')
              .get()
              .then((s) => s.docs.map((doc) => ({ $key: doc.id, ...doc.data() })));
            const oneTouchStatusTime = appStatusDates.find((x) => x.value == 'Data Collection (One Touch)')?._time;
            if (oneTouchStatusTime) {
              const date = DateTime.fromMillis(oneTouchStatusTime).toISODate();
              return dateSubmittedRecords.find((record) => {
                const recordDate = DateTime.fromMillis(record.set_at.seconds * 1000).toISODate();
                if (recordDate == date) {
                  return true;
                }
              })?.value;
            }
          }
        },
        '3.0': { survey_metadata: 'date_invoice_paid' }
      }
    },
    {
      name: 'Date Virtual Rideout',
      types: {
        '2.0': { metadata: 'date_of_virtual_rideout' },
        '3.0': {
          metadata: 'date_of_virtual_rideout',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return surveyMetadata.date_of_virtual_rideout;
          }
        }
      }
    },
    {
      name: 'Date Received',
      types: {
        '2.0': {
          app_status: 'Reviewing for Completeness (Complex)',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return appStatusDates.find((x) => x.value == 'Compiling Make Ready')?._time;
          }
        },
        '3.0': {
          app_status: 'Reviewing for Completeness (Complex)',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return appStatusDates.find((x) => x.value == 'Compiling Make Ready')?._time;
          }
        },
        regular: { app_status: 'Reviewing for Completeness' },
        'service-drop': { app_status: 'Submitted' },
        'non-fcc': { app_status: 'Submitted' }
      }
    },
    {
      name: 'Date Resubmitted',
      types: {
        '2.0': { metadata: 'date_resubmitted' },
        '3.0': { metadata: 'date_resubmitted' },
        regular: { metadata: 'date_resubmitted' }
      }
    },
    {
      name: 'Date App Accepted',
      types: {
        '2.0': { app_status: 'Generating PPL WO' },
        '3.0': { app_status: 'Generating PPL WO' },
        regular: { app_status: 'Awaiting Payment (MR ENG)' },
        'service-drop': { app_status: 'In Review' }
      }
    },
    {
      name: 'Date invoice sent ENGR payment',
      types: {
        '2.0': { app_status: 'PPL Awaiting Payment (MR Engineering)' },
        '3.0': { app_status: 'PPL Awaiting Payment (MR Engineering)' },
        regular: { app_status: 'Awaiting Payment (MR ENG)' }
      }
    },
    {
      name: 'Date payment received / engineering start date',
      types: {
        '2.0': {
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const dateSubmittedRecords = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'date_submitted')
              .get()
              .then((s) => s.docs.map((doc) => ({ $key: doc.id, ...doc.data() })));
            const oneTouchStatusTime = appStatusDates.find((x) => x.value == 'Data Collection (One Touch)')?._time;
            if (oneTouchStatusTime) {
              const date = DateTime.fromMillis(oneTouchStatusTime).toISODate();
              return dateSubmittedRecords.find((record) => {
                const recordDate = DateTime.fromMillis(record.set_at.seconds * 1000).toISODate();
                if (recordDate != date) {
                  return true;
                }
              })?.value;
            } else {
              return metadata.date_submitted;
            }
          }
        },
        '3.0': {
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const dateSubmittedRecords = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'date_submitted')
              .get()
              .then((s) => s.docs.map((doc) => ({ $key: doc.id, ...doc.data() })));
            const oneTouchStatusTime = appStatusDates.find((x) => x.value == 'Data Collection (One Touch)')?._time;
            if (oneTouchStatusTime) {
              const date = DateTime.fromMillis(oneTouchStatusTime).toISODate();
              return dateSubmittedRecords.find((record) => {
                const recordDate = DateTime.fromMillis(record.set_at.seconds * 1000).toISODate();
                if (recordDate != date) {
                  return true;
                }
              })?.value;
            } else {
              return metadata.date_submitted;
            }
          }
        },
        regular: { metadata: 'date_submitted' }
      }
    },
    {
      name: 'Date Data Collection communicated to Katapult',
      types: {}
    },
    {
      name: 'Date Resumed after Revisions',
      types: {
        '2.0': { app_status: 'OTMR Engineering (Revisions)' },
        '3.0': { app_status: 'OTMR Engineering (Revisions)' },
        regular: { app_status: 'MR Engineering (Revisions)' }
      }
    },
    {
      name: 'Date Waiting on Permits',
      types: {
        '2.0': { app_status: 'Waiting on Permits' },
        '3.0': { app_status: 'Waiting on Permits' },
        regular: { app_status: 'Waiting on Permits' }
      }
    },
    {
      name: 'Date Estimate Start',
      types: {
        '2.0': {
          app_status: 'Creating PPL Package',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return appStatusDates.find((x) => x.value == 'Awaiting Payment (MR CONST)')?._time;
          }
        },
        '3.0': { app_status: 'Creating PPL Package' },
        regular: {
          app_status: 'Creating PPL Package',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return appStatusDates.find((x) => x.value == 'Make Ready (Complete)')?._time;
          }
        }
      }
    },
    {
      name: 'Date engineering completed / invoice sent for construction payment',
      types: {
        '2.0': { app_status: 'Awaiting Payment (MR CONST)' },
        '3.0': { app_status: 'Awaiting Payment (MR CONST)' },
        regular: { app_status: 'Awaiting Payment (MR CONST)' }
      }
    },
    {
      name: 'Date Power MR Construction Start',
      types: {
        '2.0': { metadata: 'date_power_mr_construction_start' },
        '3.0': { metadata: 'date_power_mr_construction_start' },
        regular: { metadata: 'date_power_mr_construction_start' }
      }
    },
    {
      name: 'Date MR Construction Complete',
      types: {
        '2.0': {
          metadata: 'date_mr_construction_complete',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return (
              appStatusDates.find((x) => x.value == 'Verify Comm MR Completion')?._time ??
              appStatusDates.find((x) => x.value == 'Released')?._time
            );
          }
        },
        '3.0': {
          metadata: 'date_mr_construction_complete',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return (
              appStatusDates.find((x) => x.value == 'Verify Comm MR Completion')?._time ??
              appStatusDates.find((x) => x.value == 'Released')?._time
            );
          }
        },
        regular: {
          metadata: 'date_mr_construction_complete',
          fallback: (jobId, appStatusDates, metadata, surveyMetadata) => {
            return (
              appStatusDates.find((x) => x.value == 'Verify Comm MR Completion')?._time ??
              appStatusDates.find((x) => x.value == 'Released')?._time
            );
          }
        }
      }
    },
    {
      name: 'Date Verify Comm MR Complete',
      types: {
        '2.0': { app_status: 'Verify Comm MR Completion' },
        '3.0': { app_status: 'Verify Comm MR Completion' },
        regular: { app_status: 'Verify Comm MR Completion' }
      }
    },
    {
      name: 'Date Released',
      types: {
        '2.0': { app_status: 'Released' },
        '3.0': { app_status: 'Released' },
        regular: { app_status: 'Released' },
        'service-drop': { app_status: 'Released' },
        'non-fcc': { app_status: 'Released' }
      }
    },
    {
      name: 'Date Canceled',
      types: {
        '2.0': { app_status: 'Canceled' },
        '3.0': { app_status: 'Canceled' },
        regular: { app_status: 'Canceled' },
        'service-drop': { app_status: 'Canceled' },
        'non-fcc': { app_status: 'Canceled' }
      }
    },
    {
      name: 'Date Rejected',
      types: {
        '2.0': { app_status: 'Rejected' },
        '3.0': { app_status: 'Rejected' },
        regular: { app_status: 'Rejected' },
        'service-drop': { app_status: 'Rejected' },
        'non-fcc': { app_status: 'Rejected' }
      }
    },
    {
      name: 'Date Incomplete',
      types: {
        '2.0': { app_status: 'Incomplete (Complex)' },
        '3.0': { app_status: 'Incomplete (Complex)' },
        regular: { app_status: 'Incomplete' },
        'service-drop': { app_status: 'Incomplete' },
        'non-fcc': { app_status: 'Incomplete' }
      }
    },
    {
      name: 'Date On Hold',
      types: {
        '2.0': { app_status: 'On Hold' },
        '3.0': { app_status: 'On Hold' },
        regular: { app_status: 'On Hold' },
        'service-drop': { app_status: 'On Hold' },
        'non-fcc': { app_status: 'On Hold' }
      }
    },
    {
      name: 'Date for Engineering Quote',
      types: {
        '2.0': {
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const doc = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'engineering_quote_number')
              .get()
              .then((s) => s.docs[0]?.data());
            return doc?.set_at?.seconds ? doc?.set_at?.seconds * 1000 : '';
          }
        },
        '3.0': {
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const doc = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'engineering_quote_number')
              .get()
              .then((s) => s.docs[0]?.data());
            return doc?.set_at?.seconds ? doc?.set_at?.seconds * 1000 : '';
          }
        },
        regular: {
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const doc = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'engineering_quote_number')
              .get()
              .then((s) => s.docs[0]?.data());
            return doc?.set_at?.seconds ? doc?.set_at?.seconds * 1000 : '';
          }
        },
        'service-drop': {
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const doc = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'engineering_quote_number')
              .get()
              .then((s) => s.docs[0]?.data());
            return doc?.set_at?.seconds ? doc?.set_at?.seconds * 1000 : '';
          }
        },
        'non-fcc': {
          fallback: async (jobId, appStatusDates, metadata, surveyMetadata) => {
            const doc = await firebase
              .firestore()
              .collectionGroup(`attribute_events_history`)
              .where('entity_id', '==', jobId)
              .where('attribute', '==', 'engineering_quote_number')
              .get()
              .then((s) => s.docs[0]?.data());
            return doc?.set_at?.seconds ? doc?.set_at?.seconds * 1000 : '';
          }
        }
      }
    },
    {
      name: 'Date Data Collection Complete',
      types: {
        '2.0': { app_status: 'MR Engineering' },
        '3.0': { app_status: 'MR Engineering' },
        regular: { app_status: 'MR Engineering' },
        'service-drop': { app_status: 'MR Engineering' },
        'non-fcc': { app_status: 'MR Engineering' }
      }
    },
    {
      name: 'Date Awaiting PCI',
      types: {
        '2.0': { app_status: 'Awaiting Post Construction Inspection' },
        '3.0': { app_status: 'Awaiting Post Construction Inspection' },
        regular: { app_status: 'Awaiting Post Construction Inspection' },
        'service-drop': { app_status: 'Awaiting Post Construction Inspection' },
        'non-fcc': { app_status: 'Awaiting Post Construction Inspection' }
      }
    },
    {
      name: 'Date Complete',
      types: {
        '2.0': { app_status: 'Complete' },
        '3.0': { app_status: 'Complete' },
        regular: { app_status: 'Complete' },
        'service-drop': { app_status: 'Complete' },
        'non-fcc': { app_status: 'Complete' }
      }
    },
    {
      name: 'Date Survey Complete',
      types: {
        '2.0': { app_status: 'MR Engineering (One Touch)' },
        '3.0': { app_status: 'MR Engineering (One Touch)' },
        regular: { app_status: 'MR Engineering' },
        'non-fcc': { app_status: 'MR Engineering' }
      }
    }
  ];
  const csv = [columns.map((x) => x.name), ['Reserved For Explainer Text']];
  for (const jobId of jobIds) {
    console.log('getting data', jobId);
    const metadata =
      jobPermissions?.[jobId]?.metadata ??
      (await FirebaseWorker.ref(`photoheight/jobs/${jobId}/metadata`)
        .once('value')
        .then((s) => s.val()));
    const parentJob = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/parent_job`)
      .once('value')
      .then((s) => s.val());
    const surveyMetadata = parentJob
      ? (jobPermissions?.[parentJob.job_id]?.metadata ??
        (await FirebaseWorker.ref(`photoheight/jobs/${parentJob.job_id}/metadata`)
          .once('value')
          .then((s) => s.val())))
      : {};
    const appStatusDates = await firebase
      .firestore()
      .collectionGroup(`attribute_events_history`)
      .where('entity_id', '==', jobId)
      .where('attribute', '==', 'app_status')
      .get()
      .then((s) => s.docs.map((doc) => ({ $key: doc.id, ...doc.data() })));
    appStatusDates.sort((a, b) => b.set_at?.seconds - a.set_at?.seconds);
    appStatusDates.forEach((x) => (x._time = x.set_at?.seconds * 1000));

    let type = null;
    if (metadata.app_type == 'one_touch_make_ready_application') {
      type = parentJob ? '3.0' : '2.0';
    } else if (metadata.app_type == 'attachment_application') {
      if (metadata.app_attach_type == 'Cable') {
        if (attachers?.[metadata.attachment_owner]?.agreement_type == 'TRU') {
          type = 'non-fcc';
        } else if (metadata.attachment_summary == 'Service Drop') {
          type = 'service-drop';
        } else {
          type = 'regular';
        }
      } else if (['Wireless Antenna (Middle)', 'Wireless Antenna (Bottom)', 'Wireless Antenna (Top)'].includes(metadata.app_attach_type)) {
        type = 'regular';
      } else {
        type = 'non-fcc';
      }
    }
    if (type) {
      const row = [];
      for (const column of columns) {
        if (column.name == 'Bucket') {
          row.push((['2.0', '3.0'].includes(type) ? 'OT ' : '') + type);
          continue;
        }
        if (column.name == 'FCC Timeline') {
          row.push(type == 'non-fcc' ? '' : (metadata?.timeline?.name ?? ''));
          continue;
        }
        if (column.name == 'App#') {
          row.push(metadata.app_number);
          continue;
        }
        if (column.name == 'Current Status') {
          row.push(metadata.app_status);
          continue;
        }
        if (column.name == 'Pole Count') {
          row.push(metadata.pole_count);
          continue;
        }
        let value = '';
        const config = column.types[type];
        if (config?.app_status) value = appStatusDates.find((x) => x.value == config.app_status)?._time;
        else if (config?.hasOwnProperty('value')) value = config.value;
        else if (config?.metadata) value = metadata[config.metadata];
        else if (config?.survey_metadata) value = surveyMetadata[config.survey_metadata];
        if (config?.fallback && !value) value = await config.fallback(jobId, appStatusDates, metadata, surveyMetadata);
        // Convert dates
        if (typeof value == 'number' && value > 1540315523000) {
          value = DateTime.fromMillis(value).toISODate();
        }
        row.push(value ?? '');
      }
      csv.push(row);
    }
  }
  return csv;
}

async function checkMakeReadyCategory(jobId) {
  const mrCategories = {};
  const nodes = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/nodes`)
    .once('value')
    .then((s) => s.val());
  for (const nodeId in nodes) {
    const category = Path.get(nodes[nodeId], 'attributes.mr_category.*');
    if (category) {
      mrCategories[category] = true;
    }
  }
  if (mrCategories['Complex Make Ready'] || mrCategories['Medium Make Ready']) return 'Power Make Ready';
  if (mrCategories['Simple Make Ready']) return 'Only Com Make Ready';
  if (mrCategories['No Make Ready']) return 'No Make Ready';
}
